import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';

import BaseHalf from 'src/components/Half';
import BaseHeading from 'src/components/Heading';
import Image from 'src/components/Image';

const Content = tw.div`px-8 pt-44 pb-64 space-y-48 sm:px-24`;
const Heading = tw(BaseHeading)`text-5xl leading-14`;
const Subheading = tw.h4`font-bold text-navy-light my-4`;
const CenteredHeader = tw(Heading)`text-center`;
const CenteredContent = tw.div`text-center space-y-4 mt-4 mb-8`;
const Features = tw.section`flex flex-col space-y-8 sm:space-y-0 sm:space-x-16 sm:flex-row`;
const FeatureHeading = tw.h2`text-2xl font-bold text-navy-light mb-4`;
const Half = styled(BaseHalf)`
  ${({ reverse }) => reverse && tw`flex-row-reverse`}
`;

const ResearchPage = ({
  companyFeatures,
  platformBenefit,
  platformFeatures
}) => (
  <Content>
    {companyFeatures.map((feature, index) => (
      <Half key={index} reverse={!!(index % 2)}>
        <Half.Content>
          <Image src={feature.image.imgSrc} alt={feature.image.alt} />
        </Half.Content>
        <Half.Content>
          <Heading>{feature.heading}</Heading>
          <Subheading>{feature.subheading}</Subheading>
          <p>{feature.text}</p>
        </Half.Content>
      </Half>
    ))}
    <section>
      <CenteredHeader>{platformBenefit.heading}</CenteredHeader>
      <CenteredContent>
        <p>{platformBenefit.text}</p>
        <div>
          <Image
            src={platformBenefit.image.imgSrc}
            alt={platformBenefit.image.alt}
          />
        </div>
      </CenteredContent>
    </section>
    <Features>
      {platformFeatures.map((feature, index) => (
          <div key={index}>
            <FeatureHeading>{feature.heading}</FeatureHeading>
            <p>{feature.text}</p>
          </div>
        ))}
    </Features>
  </Content>
);

ResearchPage.propTypes = {
  companyFeatures: PropTypes.array.isRequired,
  platformBenefit: PropTypes.object.isRequired,
  platformFeatures: PropTypes.array.isRequired
};
export default ResearchPage;
