import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from 'src/components/SEO';
import ResearchPage from 'src/components/pages/ResearchPage';

const Research = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <>
      <SEO title={frontmatter.title} />
      <ResearchPage
        companyFeatures={frontmatter.companyFeatures}
        platformBenefit={frontmatter.platformBenefit}
        platformFeatures={frontmatter.platformFeatures}
      />
    </>
  );
};

Research.propTypes = {
  data: PropTypes.object.isRequired
};

export const pageQuery = graphql`
  query ResearchPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "research-page" } }) {
      frontmatter {
        title
        companyFeatures {
          heading
          image {
            alt
            imgSrc {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
          }
          subheading
          text
        }
        platformBenefit {
          heading
          image {
            alt
            imgSrc {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED)
              }
            }
          }
          text
        }
        platformFeatures {
          heading
          text
        }
      }
    }
  }
`;
export default Research;
